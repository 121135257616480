export default [
  {
    title: 'Home',
    route: 'admin',
    icon: 'HomeIcon',
  },
  {
    title: 'Automate Class',
    route: 'automate-class',
    icon: 'ClockIcon',
  },
  {
    title: 'FAQ',
    href: 'https://ipstudio.co/support/live-collections/zoom/',
    icon: 'BookOpenIcon',
  },
]
